import { useAuth } from "components/auth/AuthProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const { logout } = useAuth(); // Assuming you have a logout function in your auth context
  const navigate = useNavigate();

  useEffect(() => {
    logout("logout component"); // Call the logout function
    navigate("/"); // Redirect to the home page or login page
  }, [logout, navigate]);

  return null; // Optionally, return a loading spinner or a message
}

export default Logout;
