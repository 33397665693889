import { useState } from "react";
import { useLazyQuery } from "@apollo/client"; // Make sure Apollo Client is installed
import md5 from "md5";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import bgImage from "assets/images/bg_kage_banner.png";
import { SUBMIT_LOGIN_REQUEST } from "queries/users";
import { useNavigate } from "react-router-dom";
import { useAuth } from "components/auth/AuthProvider";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import RoutesConfig from "routes";

function SignInBasic() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState(""); // State for username
  const [password, setPassword] = useState(""); // State for password
  const { storeTokens } = useAuth();
  const [loginError, setLoginError] = useState("");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [sendLoginRequest, { loading }] = useLazyQuery(SUBMIT_LOGIN_REQUEST);
  const routes = RoutesConfig();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendLoginRequest({
        variables: {
          username: username,
          password: md5(password),
        },
      });

      // Handle the response here
      if (response.data.login.pass) {
        console.log("Login successful", response.data.login);
        storeTokens({
          accessToken: response.data.login.access_token,
          refreshToken: response.data.login.refresh_token,
        });
        navigate("/pages/dashboard");
      } else {
        console.error("Login failed:", response.data.login.error);
        setLoginError(response.data.login.error);
      }
    } catch (err) {
      console.error("Error logging in:", err);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign in
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Username or Email"
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </MKBox>
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MKTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;Remember me
                    </MKTypography>
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Signing in..." : "Sign in"}
                    </MKButton>
                  </MKBox>
                  {loginError && (
                    <MKBox mt={2}>
                      <MKTypography variant="body2" color="error">
                        {loginError}
                      </MKTypography>
                    </MKBox>
                  )}
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/pages/authentication/register"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign up
                      </MKTypography>{" "}
                      |{" "}
                      <MKTypography
                        component={Link}
                        to="/"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Home
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignInBasic;
