/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client"; // Make sure Apollo Client is installed
import { REFRESH_TOKEN_REQUEST } from "queries/users";

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [sendRefreshRequest] = useLazyQuery(REFRESH_TOKEN_REQUEST);
  const [tokens, setTokens] = useState({
    accessToken: null,
    refreshToken: null,
  });

  const logout = (from) => {
    console.log("logout has been called", from);
    setTokens({
      accessToken: null,
      refreshToken: null,
    });
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };

  // Function to store tokens in local storage
  const storeTokens = (newTokens) => {
    setTokens(newTokens);
    localStorage.setItem("accessToken", newTokens.accessToken);
    localStorage.setItem("refreshToken", newTokens.refreshToken);
  };

  // Function to refresh the access token
  const refreshAccessToken = async () => {
    try {
      const response = await sendRefreshRequest({
        variables: {
          refresh_token: tokens.refreshToken,
        },
      });

      // Handle the response here
      if (!response.data.refresh.error) {
        console.log("Refresh successful", response.data.login);
        storeTokens({
          accessToken: response.data.refresh.access_token,
          refreshToken: response.data.refresh.refresh_token,
        });
      } else {
        console.error("Refresh failed:", response.data.login.error);
        logout("refresh failure");
      }
    } catch (err) {
      console.error("Error refreshing tokens:", err);
    }
  };

  // Load tokens from local storage on mount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      setTokens({ accessToken, refreshToken });
    }
  }, []);

  // Set an interval to refresh the access token every X minutes
  useEffect(() => {
    const interval = setInterval(() => {
      if (tokens.refreshToken) {
        refreshAccessToken();
      }
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [tokens.refreshToken]);

  return (
    <AuthContext.Provider value={{ tokens, storeTokens, logout }}>{children}</AuthContext.Provider>
  );
};

// Custom hook to use the Auth context
const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };
