// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Card from "@mui/material/Card";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import bgImage from "assets/images/bg_kage_banner.png";
import RoutesConfig from "routes";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SEND_CONTACT_FORM_REQUEST } from "queries/users";

function ContactUs() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [message, setMessage] = useState("");
  const [contactUsError, setContactUsError] = useState("");
  const [sendContactUsRequest, { loading }] = useLazyQuery(SEND_CONTACT_FORM_REQUEST);
  const routes = RoutesConfig();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendContactUsRequest({
        variables: {
          name: name,
          email: email,
          message: message,
        },
      });

      // Handle the response here
      if (response.data.login.pass) {
        console.log("Contact successful", response.data.login);
      } else {
        console.error("Contact failed:", response.data.login.error);
        setContactUsError(response.data.login.error);
      }
    } catch (err) {
      console.error("Error contacting:", err);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h3" color="white">
                  Contact us
                </MKTypography>
              </MKBox>
              <MKBox p={3}>
                <MKBox
                  width="100%"
                  component="form"
                  method="post"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Full Name"
                        InputLabelProps={{ shrink: true }}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        type="email"
                        variant="standard"
                        label="Email"
                        InputLabelProps={{ shrink: true }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        variant="standard"
                        label="What can we help you?"
                        placeholder="Describe your problem in at least 250 characters"
                        InputLabelProps={{ shrink: true }}
                        multiline
                        fullWidth
                        rows={6}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Sending..." : "Send Message"}
                    </MKButton>
                    {contactUsError && (
                      <MKBox mt={2}>
                        <MKTypography variant="body2" color="error">
                          {contactUsError}
                        </MKTypography>
                      </MKBox>
                    )}
                  </Grid>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default ContactUs;
