import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "components/auth/AuthProvider";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";

const container = document.getElementById("root");
const client = new ApolloClient({
  uri: "https://api.prod.kagesolutions.io/graphql",
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          items: {
            merge: false,
          },
        },
      },
    },
  }),
});

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  </BrowserRouter>
);
