// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// Images
import bgImage from "assets/images/bg_kage_banner.png";
import RoutesConfig from "routes";
import { Card, Typography } from "@mui/material";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

function PrivacyPolicy() {
  const routes = RoutesConfig();
  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        display="flex" // Enable flexbox
        flexDirection="column" // Ensure the items are stacked vertically (optional based on your layout)
        px={1}
        width="100vw"
        minHeight="100vh"
        mx="auto"
        position="relative"
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
        margin="auto"
        zIndex={2}
        pt={10} // Optional padding to account for the navbar
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center" // Aligns content below the menu
          width="90%" // Set to 90% of viewport width
        >
          <Grid item xs={12} width="80%" margin="auto">
            <Card>
              <div
                style={{
                  padding: "20px",
                  maxWidth: "90%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h3" gutterBottom>
                  Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                  At Kage Solutions LLC, we value and respect your privacy. This Privacy Policy
                  outlines how we collect, use, and protect your personal information when you
                  interact with our services.
                </Typography>

                <Typography variant="h5" gutterBottom>
                  Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                  When you register on our website, we collect the following information:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">
                      <strong>Email Address:</strong> We collect your email address during the
                      registration process for communication purposes and account management.
                    </Typography>
                  </li>
                </ul>

                <Typography variant="h5" gutterBottom>
                  How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                  We use the email address you provide solely for the following purposes:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">
                      To manage your account and provide access to our services.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      To communicate important updates, changes, or notifications regarding your
                      account.
                    </Typography>
                  </li>
                </ul>

                <Typography variant="h5" gutterBottom>
                  Information Sharing
                </Typography>
                <Typography variant="body1" paragraph>
                  We do not sell, trade, or otherwise transfer your email address to any third
                  parties. Your email is used solely for internal purposes and is protected with
                  strict confidentiality.
                </Typography>

                <Typography variant="h5" gutterBottom>
                  Data Security
                </Typography>
                <Typography variant="body1" paragraph>
                  We implement various security measures to ensure the safety of your personal
                  information. However, please be aware that no method of transmission over the
                  Internet or electronic storage is 100% secure.
                </Typography>

                <Typography variant="h5" gutterBottom>
                  Your Consent
                </Typography>
                <Typography variant="body1" paragraph>
                  By using our website and services, you consent to the collection and use of your
                  email address in accordance with this Privacy Policy.
                </Typography>

                <Typography variant="h5" gutterBottom>
                  Changes to This Policy
                </Typography>
                <Typography variant="body1" paragraph>
                  We may update this Privacy Policy from time to time. Any changes will be posted on
                  this page with an updated effective date.
                </Typography>

                <Typography variant="h5" gutterBottom>
                  Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                  If you have any questions about this Privacy Policy, feel free to contact us at
                  support@kagesolutions.io.
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PrivacyPolicy;
