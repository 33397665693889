import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Card, CardContent, CardMedia, Typography, Grid, Button } from "@mui/material";
import MKBox from "components/MKBox";
import bgImage from "assets/images/bg_kage_banner.png";
import { FETCH_USER_DATA_REQUEST } from "queries/users";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "components/auth/AuthProvider";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import RoutesConfig from "routes";
import MKTypography from "components/MKTypography";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import PaymentMethodForm from "components/stripe/payment_methods_form";

function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({});
  const [subData, setSubData] = useState([]);
  const { logout, tokens } = useAuth();
  const [getUserData, { loading }] = useLazyQuery(FETCH_USER_DATA_REQUEST);
  const routes = RoutesConfig();

  useEffect(() => {
    console.log("Page visited:", location.pathname);
    const fetchUserData = async () => {
      if (tokens && tokens.accessToken) {
        try {
          const response = await getUserData({
            variables: {
              access_token: tokens?.accessToken,
            },
          });

          if (response.errors) {
            console.error("Fetch user data failed:", response.errors);
            logout("dashboard");
            navigate("/pages/authentication/sign-in");
          } else {
            if (response.data.fetchUser.success) {
              setUserData(response.data.fetchUser.data);
            } else {
              logout("dashboard");
              navigate("/pages/authentication/sign-in");
            }
          }
        } catch (err) {
          console.error("Error fetching user data:", err);
        }
      }
    };

    fetchUserData();
  }, [tokens, getUserData, logout, navigate, location.pathname]);

  useEffect(() => {
    const cardData = [];
    if (userData.subscriptions) {
      for (const sub of userData.subscriptions) {
        let description = "";
        if (sub.product.toLowerCase().includes("api")) {
          description = `
          Plan: ${sub.plan}<br />
          Limit: ${sub.limit === 99999999999999 ? "unlimited" : sub.limit.toLocaleString()}<br />
          Used: ${sub.uses.toLocaleString()}<br />
          Left: ${sub.limit === 99999999999999 ? "unlimited" : sub.left.toLocaleString()}
          `;
        }
        cardData.push({
          title: sub.product,
          expires: sub.expiration,
          description: description,
          key: sub.key,
          actionLabel: "Copy Key",
        });
      }
    }
    setSubData(cardData);
  }, [userData]);

  if (loading) {
    return (
      <>
        <DefaultNavbar routes={routes} dark />
        <MKBox
          position="absolute"
          top={0}
          left={0}
          zIndex={1}
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
          <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card>LOADING DATA</Card>
            </Grid>
          </Grid>
        </MKBox>
      </>
    );
  }
  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        display="flex" // Enable flexbox
        flexDirection="column" // Ensure the items are stacked vertically (optional based on your layout)
        px={1}
        width="100vw"
        minHeight="100vh"
        mx="auto"
        position="relative"
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
        margin="auto"
        zIndex={2}
        pt={10} // Optional padding to account for the navbar
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="flex-start" // Aligns content below the menu
          width="90%" // Set to 90% of viewport width
        >
          <Grid item xs={12}>
            <Card>
              <MKTypography
                variant="h4"
                color="black"
                mt={3}
                mb={1}
                p={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Welcome back: {userData.username}
              </MKTypography>
              <Grid container spacing={2} p={2}>
                {subData.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index} color={"black"}>
                    <Card>
                      <CardMedia component="img" height="120" image={bgImage} alt={item.title} />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {item.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {"Renews: "}
                          {new Date(item.expires * 1000).toLocaleDateString() +
                            " " +
                            new Date(item.expires * 1000).toLocaleTimeString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <span dangerouslySetInnerHTML={{ __html: item.description }} />
                        </Typography>
                        <Grid container spacing={2} alignItems="center" justifyContent={"center"}>
                          <Grid item>
                            <Button
                              variant="contained"
                              style={{ marginTop: "10px", color: "white" }}
                              onClick={() => navigator.clipboard.writeText(item.key)}
                            >
                              {item.actionLabel}
                            </Button>
                          </Grid>
                          {item.id && item.id.includes("sub") ? (
                            <Grid item>
                              <PaymentMethodForm subscriptionId={item.id} />
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Dashboard;
