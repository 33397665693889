import React, { useEffect, useState } from "react";
// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "layouts/pages/navigate/about-us";
import ContactUs from "layouts/pages/navigate/contact-us";
import SignIn from "layouts/pages/authentication/sign-in";
import Register from "layouts/pages/authentication/register";

// Sections
import Navbars from "layouts/sections/navigation/navbars";
import NavTabs from "layouts/sections/navigation/nav-tabs";
import Pagination from "layouts/sections/navigation/pagination";
import Terms from "pages/Navigate/Terms";
import { useAuth } from "components/auth/AuthProvider";
import Logout from "components/logout";
import Dashboard from "pages/Navigate/DashBoard";
import PrivacyPolicy from "pages/Navigate/PrivacyPolicy";
import Pricing from "layouts/pages/navigate/pricing";

function RoutesConfig() {
  const { tokens } = useAuth();
  const [routes, setRoute] = useState([
    {
      name: "navigation",
      icon: <Icon>dashboard</Icon>,
      columns: 1,
      rowsPerColumn: 2,
      collapse: [
        {
          name: "visit",
          collapse: [
            {
              name: "what do we do?",
              route: "/pages/navigate/about-us",
              component: <AboutUs />,
            },
            {
              name: "terms of service",
              route: "/pages/navigate/terms",
              component: <Terms />,
            },
            {
              name: "privacy policy",
              route: "/pages/navigate/privacy-policy",
              component: <PrivacyPolicy />,
            },
            {
              name: "contact us",
              route: "/pages/navigate/contact-us",
              component: <ContactUs />,
            },
          ],
        },
        {
          name: "account",
          collapse: [
            {
              name: "sign in",
              route: "/pages/authentication/sign-in",
              component: <SignIn />,
            },
            {
              name: "register",
              route: "/pages/authentication/register",
              component: <Register />,
            },
          ],
        },
      ],
    },
    {
      name: "services",
      icon: <Icon>view_day</Icon>,
      collapse: [
        {
          name: "pricing",
          description: "Check out our pricing",
          route: "/pages/navigate/pricing",
          component: <Pricing />,
        },
        {
          name: "api status",
          description: "See the status of our apis",
          href: "https://status.kageapis.com/",
          dropdown: false,
        },
        {
          name: "anti bot consultations",
          description: "Check out our anti bot consultations",
          dropdown: true,
          collapse: [
            {
              name: "akamai",
              href: "https://docs.kageapis.com/docs/category/akamai-apis",
            },
            {
              name: "perimeter x",
              route: "https://docs.kageapis.com/docs/category/perimeterx-px-apis",
            },
            {
              name: "incapsula",
              route: "https://docs.kageapis.com/docs/category/incapsula-reese84-apis",
            },
            {
              name: "datadome",
              route: "#",
            },
          ],
        },
        {
          name: "sportsbook apis",
          description: "We offer sportsbook apis as well!",
          dropdown: true,
          collapse: [
            {
              name: "odds apis",
              route: "/sections/navigation/navbars",
              component: <Navbars />,
            },
            {
              name: "arbitrage apis",
              route: "/sections/navigation/nav-tabs",
              component: <NavTabs />,
            },
            {
              name: "positive ev apis",
              route: "/sections/navigation/pagination",
              component: <Pagination />,
            },
          ],
        },
        {
          name: "custom software",
          description: "do you need consultation?",
          dropdown: true,
          collapse: [
            {
              name: "automations",
              route: "/sections/navigation/navbars",
              component: <Navbars />,
            },
            {
              name: "consultations",
              route: "/sections/navigation/navbars",
              component: <Navbars />,
            },
            {
              name: "request quote",
              route: "/sections/navigation/nav-tabs",
              component: <NavTabs />,
            },
          ],
        },
      ],
    },
    {
      name: "github",
      icon: <GitHubIcon />,
      href: "https://www.github.com/KageSolutions",
    },
  ]);

  useEffect(() => {
    console.log("tokens have chaged", tokens);
    if (tokens && tokens.accessToken) {
      setRoute([
        {
          name: "navigation",
          icon: <Icon>dashboard</Icon>,
          columns: 1,
          rowsPerColumn: 2,
          collapse: [
            {
              name: "visit",
              collapse: [
                {
                  name: "what do we do?",
                  route: "/pages/navigate/about-us",
                  component: <AboutUs />,
                },
                {
                  name: "terms of service",
                  route: "/pages/navigate/terms",
                  component: <Terms />,
                },
                {
                  name: "privacy policy",
                  route: "/pages/navigate/privacy-policy",
                  component: <PrivacyPolicy />,
                },
                {
                  name: "contact us",
                  route: "/pages/navigate/contact-us",
                  component: <ContactUs />,
                },
              ],
            },
          ],
        },
        {
          name: "services",
          icon: <Icon>view_day</Icon>,
          collapse: [
            {
              name: "pricing",
              description: "Check out our pricing",
              route: "/pages/navigate/pricing",
              component: <Pricing />,
            },
            {
              name: "api status",
              description: "See the status of our apis",
              href: "https://status.kageapis.com/",
              dropdown: false,
            },
            {
              name: "anti bot consultations",
              description: "Check out our anti bot consultations",
              dropdown: true,
              collapse: [
                {
                  name: "akamai",
                  href: "https://docs.kageapis.com/docs/category/akamai-apis",
                },
                {
                  name: "perimeter x",
                  route: "https://docs.kageapis.com/docs/category/perimeterx-px-apis",
                },
                {
                  name: "incapsula",
                  route: "https://docs.kageapis.com/docs/category/incapsula-reese84-apis",
                },
                {
                  name: "datadome",
                  route: "#",
                },
              ],
            },
            {
              name: "sportsbook apis",
              description: "We offer sportsbook apis as well!",
              dropdown: true,
              collapse: [
                {
                  name: "odds apis",
                  route: "/sections/navigation/navbars",
                  component: <Navbars />,
                },
                {
                  name: "arbitrage apis",
                  route: "/sections/navigation/nav-tabs",
                  component: <NavTabs />,
                },
                {
                  name: "positive ev apis",
                  route: "/sections/navigation/pagination",
                  component: <Pagination />,
                },
              ],
            },
            {
              name: "custom software",
              description: "do you need consultation?",
              dropdown: true,
              collapse: [
                {
                  name: "automations",
                  route: "/sections/navigation/navbars",
                  component: <Navbars />,
                },
                {
                  name: "consultations",
                  route: "/sections/navigation/navbars",
                  component: <Navbars />,
                },
                {
                  name: "request quote",
                  route: "/sections/navigation/nav-tabs",
                  component: <NavTabs />,
                },
              ],
            },
          ],
        },
        {
          name: "profile",
          icon: <Icon>person</Icon>,
          collapse: [
            {
              name: "user dashboard",
              route: "/pages/dashboard",
              component: <Dashboard />, // Replace with actual component
            },
            {
              name: "logout",
              route: "/logout",
              component: <Logout />, // Pass the component, not a function
            },
          ],
        },
        {
          name: "github",
          icon: <GitHubIcon />,
          href: "https://www.github.com/KageSolutions",
        },
      ]);
    } else {
      setRoute([
        {
          name: "navigation",
          icon: <Icon>dashboard</Icon>,
          columns: 1,
          rowsPerColumn: 2,
          collapse: [
            {
              name: "visit",
              collapse: [
                {
                  name: "what do we do?",
                  route: "/pages/navigate/about-us",
                  component: <AboutUs />,
                },
                {
                  name: "terms of service",
                  route: "/pages/navigate/terms",
                  component: <Terms />,
                },
                {
                  name: "privacy policy",
                  route: "/pages/navigate/privacy-policy",
                  component: <PrivacyPolicy />,
                },
                {
                  name: "contact us",
                  route: "/pages/navigate/contact-us",
                  component: <ContactUs />,
                },
              ],
            },
            {
              name: "account",
              collapse: [
                {
                  name: "sign in",
                  route: "/pages/authentication/sign-in",
                  component: <SignIn />,
                },
                {
                  name: "register",
                  route: "/pages/authentication/register",
                  component: <Register />,
                },
              ],
            },
          ],
        },
        {
          name: "services",
          icon: <Icon>view_day</Icon>,
          collapse: [
            {
              name: "pricing",
              description: "Check out our pricing",
              route: "/pages/navigate/pricing",
              component: <Pricing />,
            },
            {
              name: "api status",
              description: "See the status of our apis",
              href: "https://status.kageapis.com/",
              dropdown: false,
            },
            {
              name: "anti bot consultations",
              description: "Check out our anti bot consultations",
              dropdown: true,
              collapse: [
                {
                  name: "akamai",
                  href: "https://docs.kageapis.com/docs/category/akamai-apis",
                },
                {
                  name: "perimeter x",
                  route: "https://docs.kageapis.com/docs/category/perimeterx-px-apis",
                },
                {
                  name: "incapsula",
                  route: "https://docs.kageapis.com/docs/category/incapsula-reese84-apis",
                },
                {
                  name: "datadome",
                  route: "#",
                },
                {
                  name: "discord",
                  route: "https://discord.gg/9WuxFkm9",
                },
              ],
            },
            {
              name: "sportsbook apis",
              description: "We offer sportsbook apis as well!",
              dropdown: true,
              collapse: [
                {
                  name: "odds apis",
                  route: "/sections/navigation/navbars",
                  component: <Navbars />,
                },
                {
                  name: "arbitrage apis",
                  route: "/sections/navigation/nav-tabs",
                  component: <NavTabs />,
                },
                {
                  name: "positive ev apis",
                  route: "/sections/navigation/pagination",
                  component: <Pagination />,
                },
              ],
            },
            {
              name: "custom software",
              description: "do you need consultation?",
              dropdown: true,
              collapse: [
                {
                  name: "automations",
                  route: "/sections/navigation/navbars",
                  component: <Navbars />,
                },
                {
                  name: "consultations",
                  route: "/sections/navigation/navbars",
                  component: <Navbars />,
                },
                {
                  name: "request quote",
                  route: "/sections/navigation/nav-tabs",
                  component: <NavTabs />,
                },
              ],
            },
          ],
        },
        {
          name: "github",
          icon: <GitHubIcon />,
          href: "https://www.github.com/KageSolutions",
        },
      ]);
    }
  }, [tokens]);
  console.log(routes);
  return routes;
}

export default RoutesConfig;
