import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import md5 from "md5";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import bgImage from "assets/images/bg_kage_banner.png";
import { SUBMIT_REGISTER_REQUEST } from "queries/users";
import { useNavigate } from "react-router-dom";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import RoutesConfig from "routes";

function RegisterBasic() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [registerError, setRegisterError] = useState("");
  const [sendRegisterRequest, { loading }] = useLazyQuery(SUBMIT_REGISTER_REQUEST);
  const routes = RoutesConfig();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRegisterRequest({
        variables: {
          email: email,
          username: username,
          password: md5(password.toString()),
          company: company,
        },
      });

      // Handle the response here
      if (response.data.register.success) {
        console.log("Register successful", response.data.register);
        navigate("/pages/authentication/sign-in");
      } else {
        console.error("Register failed:", response.data.register.details);
        setRegisterError(response.data.register.details);
      }
    } catch (err) {
      console.error("Error registering:", err);
    }
  };

  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Register For An Account
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form" onSubmit={handleSubmit}>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Username"
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="text"
                      label="Company (*optional)"
                      fullWidth
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput
                      type="password"
                      label="Password"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Registering..." : "Register"}
                    </MKButton>
                  </MKBox>
                  {registerError && (
                    <MKBox mt={2}>
                      <MKTypography variant="body2" color="error">
                        {registerError}
                      </MKTypography>
                    </MKBox>
                  )}
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/pages/authentication/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </MKTypography>{" "}
                      |{" "}
                      <MKTypography
                        component={Link}
                        to="/"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Home
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default RegisterBasic;
