// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Kage Solutions",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/stocksportsio",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/kagesolutions",
    },
  ],
  menus: [
    {
      name: "company",
      items: [{ name: "about us", href: "#" }],
    },
    {
      name: "resources",
      items: [{ name: "affiliate program", href: "#" }],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "#" },
        { name: "jobs", href: "#" },
      ],
    },
    {
      name: "testimonials",
      items: [],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Kage Solutions, LLC.
    </MKTypography>
  ),
};
