// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// Images
import bgImage from "assets/images/bg_kage_banner.png";
import RoutesConfig from "routes";
import { Card, Typography } from "@mui/material";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

function AboutUs() {
  const routes = RoutesConfig();
  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        display="flex" // Enable flexbox
        flexDirection="column" // Ensure the items are stacked vertically (optional based on your layout)
        px={1}
        width="100vw"
        minHeight="100vh"
        mx="auto"
        position="relative"
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
        margin="auto"
        zIndex={2}
        pt={10} // Optional padding to account for the navbar
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center" // Aligns content below the menu
          width="90%" // Set to 90% of viewport width
        >
          <Grid item xs={12} width="80%" margin="auto">
            <Card>
              <div
                style={{
                  padding: "20px",
                  maxWidth: "90%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h3" gutterBottom>
                  What We Do
                </Typography>
                <Typography variant="body1" paragraph>
                  At Kage Solutions LLC, we are dedicated to delivering innovative solutions and
                  exceptional services in the field of software engineering. Our team of experts
                  excels in:
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Software Consulting
                </Typography>
                <Typography variant="body1" paragraph>
                  We offer expert consulting services to help businesses streamline their software
                  development processes, solve technical challenges, and implement the best
                  solutions tailored to their needs.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  API Key Sales
                </Typography>
                <Typography variant="body1" paragraph>
                  We provide access to our powerful APIs through API key subscriptions, allowing
                  developers and businesses to integrate our solutions into their applications
                  seamlessly.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Custom Software Development
                </Typography>
                <Typography variant="body1" paragraph>
                  From concept to completion, we design and develop custom software tailored
                  specifically to your business requirements.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Payment Method Integrations
                </Typography>
                <Typography variant="body1" paragraph>
                  We specialize in integrating payment methods, including Stripe and various other
                  solutions, to ensure secure and seamless transactions for your business.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Website Creation
                </Typography>
                <Typography variant="body1" paragraph>
                  Our team designs and builds beautiful, responsive websites that are tailored to
                  your brand and optimized for performance.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  Analytics Implementations
                </Typography>
                <Typography variant="body1" paragraph>
                  We offer robust analytics solutions, integrating tools that help you track,
                  analyze, and optimize user behavior to drive better business decisions.
                </Typography>
                <Typography variant="h5" gutterBottom>
                  And Much More
                </Typography>
                <Typography variant="body1" paragraph>
                  We are always expanding our services to meet the ever-evolving needs of our
                  clients. Whatever your software needs are, we can help you bring your ideas to
                  life.
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
