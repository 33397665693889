import { gql } from "@apollo/client";

export const SUBMIT_LOGIN_REQUEST = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      error
      access_token
      refresh_token
      pass
    }
  }
`;

export const SUBMIT_REGISTER_REQUEST = gql`
  query register($email: String!, $username: String!, $password: String!, $company: String!) {
    register(email: $email, username: $username, password: $password, company: $company) {
      success
      details
    }
  }
`;

export const REFRESH_TOKEN_REQUEST = gql`
  query refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      access_token
      refresh_token
      error
    }
  }
`;

export const FETCH_USER_DATA_REQUEST = gql`
  query fetchUser($access_token: String!) {
    fetchUser(access_token: $access_token) {
      success
      error
      data {
        id
        level
        username
        email
        customerId
        lastLogin
        registerDate
        subscriptions {
          id
          key
          product
          limit
          uses
          left
          expiration
          plan
          price
        }
      }
    }
  }
`;

export const SEND_CONTACT_FORM_REQUEST = gql`
  query contactForm($access_token: String!) {
    contactForm(access_token: $access_token) {
      success
      error
    }
  }
`;
