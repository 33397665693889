/* eslint-disable react/no-unescaped-entities */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Images
import bgImage from "assets/images/bg_kage_banner.png";
import RoutesConfig from "routes";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";
import { Card } from "@mui/material";

function Terms() {
  const routes = RoutesConfig();
  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        display="flex" // Enable flexbox
        flexDirection="column" // Ensure the items are stacked vertically (optional based on your layout)
        px={1}
        width="100vw"
        minHeight="100vh"
        mx="auto"
        position="relative"
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
        margin="auto"
        zIndex={2}
        pt={10} // Optional padding to account for the navbar
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="flex-start" // Aligns content below the menu
          width="90%" // Set to 90% of viewport width
        >
          <Grid item xs={12} width="80%" margin="auto">
            <Card>
              <div
                style={{
                  padding: "20px",
                  maxWidth: "90%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                <h1>Terms of Service</h1>
                <p>
                  <strong>Last Updated:</strong> 10/10/2024
                </p>

                <p>
                  Welcome to Kage Solutions, LLC. By accessing or using our website
                  https://www.kagesolutions.io/, services, products, or applications (collectively,
                  the "Services"), you agree to comply with and be bound by the following Terms of
                  Service (the "Terms"). Please read these Terms carefully before using our
                  Services. If you do not agree to these Terms, you must not access or use the
                  Services.
                </p>

                <h2>1. Acceptance of Terms</h2>
                <p>
                  By using the Services, you acknowledge that you have read, understood, and agree
                  to be bound by these Terms, as well as any additional terms, policies, and
                  guidelines referenced herein. These Terms apply to all users, including visitors,
                  registered users, and others who access or use the Services.
                </p>

                <h2>2. Changes to the Terms</h2>
                <p>
                  We reserve the right to update or modify these Terms at any time. Any changes to
                  the Terms will be posted on this page, and the "Last Updated" date will be revised
                  accordingly. Your continued use of the Services following the posting of any
                  changes constitutes your acceptance of such changes.
                </p>

                <h2>3. Eligibility</h2>
                <p>
                  You must be at least 18 years of age to use the Services. By accessing or using
                  the Services, you represent and warrant that you are at least 18 years old and
                  have the legal capacity to enter into these Terms.
                </p>

                <h2>4. Account Registration</h2>
                <p>
                  To access certain features of our Services, you may be required to create an
                  account. You agree to provide accurate, current, and complete information when
                  creating an account and to promptly update this information if necessary. You are
                  solely responsible for maintaining the confidentiality of your account login
                  information and for all activities that occur under your account. You agree to
                  notify us immediately of any unauthorized access or use of your account.
                </p>

                <h2>5. Use of Services</h2>
                <p>
                  You agree to use the Services for lawful purposes and in accordance with these
                  Terms. You are prohibited from:
                </p>
                <ul>
                  <li>
                    Using the Services in any way that violates applicable laws or regulations.
                  </li>
                  <li>
                    Engaging in any activity that could disrupt, damage, or impair the functioning
                    of the Services or interfere with any other party’s use of the Services.
                  </li>
                  <li>
                    Attempting to gain unauthorized access to any part of the Services, accounts,
                    computer systems, or networks.
                  </li>
                  <li>
                    Using any automated means, including bots, scripts, or crawlers, to access or
                    interact with the Services.
                  </li>
                </ul>

                <h2>6. Intellectual Property</h2>
                <p>
                  All content, materials, and features available on or through the Services,
                  including but not limited to software, code, text, graphics, logos, images, and
                  trademarks, are the property of Kage Solutions LLC or its licensors and are
                  protected by intellectual property laws. You may not copy, modify, distribute, or
                  create derivative works from any part of the Services without our prior written
                  consent.
                </p>

                <h2>7. User-Generated Content</h2>
                <p>
                  You may have the ability to submit content, such as comments, code, or project
                  contributions, to the Services. By submitting content, you grant us a
                  non-exclusive, royalty-free, worldwide, perpetual license to use, modify,
                  reproduce, and distribute your content in connection with the Services. You
                  represent and warrant that you own or have the necessary rights to submit the
                  content and that the content does not violate any third-party rights.
                </p>

                <h2>8. Payment and Subscription Terms</h2>
                <p>
                  Some features of the Services may be subject to payment of fees or subscription
                  charges. By selecting a paid service, you agree to pay the applicable fees.
                  Payment details and subscription terms will be provided at the time of purchase.
                  All fees are non-refundable unless otherwise specified in our refund policy.
                </p>

                <h2>9. Third-Party Services</h2>
                <p>
                  The Services may include links to third-party websites, services, or content that
                  are not owned or controlled by Kage Solutions LLC. We are not responsible for the
                  content, policies, or practices of third-party sites or services. Your
                  interactions with third-party services are at your own risk.
                </p>

                <h2>10. Limitation of Liability</h2>
                <p>
                  To the fullest extent permitted by law, Kage Solutions LLC and its affiliates,
                  officers, directors, employees, and agents will not be liable for any indirect,
                  incidental, special, or consequential damages arising out of or related to your
                  use of the Services. This includes, but is not limited to, damages for loss of
                  profits, data, or goodwill, even if we have been advised of the possibility of
                  such damages.
                </p>

                <h2>11. Disclaimer of Warranties</h2>
                <p>
                  The Services are provided "as is" and "as available," without any warranties of
                  any kind, either express or implied. We do not warrant that the Services will be
                  error-free, uninterrupted, or free of viruses or harmful components.
                </p>

                <h2>12. Termination</h2>
                <p>
                  We may terminate or suspend your access to the Services at any time, without
                  notice or liability, if we determine that you have violated these Terms or for any
                  other reason. Upon termination, your right to use the Services will immediately
                  cease.
                </p>

                <h2>13. Governing Law</h2>
                <p>
                  These Terms shall be governed by and construed in accordance with the laws of New
                  Jersey, without regard to its conflict of law principles. You agree to submit to
                  the exclusive jurisdiction of the courts located in New Jersey to resolve any
                  dispute arising out of or related to these Terms or the Services.
                </p>

                <h2>14. Contact Us</h2>
                <p>
                  If you have any questions about these Terms, please contact us at
                  support@kagesolutions.io.
                </p>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Terms;
