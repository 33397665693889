import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

const PaymentMethodForm = (subscriptionId) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setSuccessMessage(null);
    } else {
      setSuccessMessage(`Payment Method Created: ${paymentMethod.id} for sub: ${subscriptionId}`);

      setError(null);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        style={{ marginTop: "10px", color: "white" }}
        onClick={handleOpen}
      >
        Update Payment
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Payment Method</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <CardElement options={{ hidePostalCode: true }} />
            {error && <div>{error}</div>}
            {successMessage && <div>{successMessage}</div>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} type="submit" disabled={!stripe} color="primary">
            Create Payment Method
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentMethodForm;
