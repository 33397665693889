// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// Images
import bgImage from "assets/images/bg_kage_banner.png";
import RoutesConfig from "routes";
import { Box, Button, Card, Paper, Typography } from "@mui/material";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

const pricingData = [
  {
    title: "Akamai Web Packages",
    plans: [
      {
        name: "Trial",
        usage: "500 req / Week",
        price: "Free one-time, one-week trial for all clients",
      },
      { name: "Starter", usage: "100,000 req / Month", price: "$195 per month" },
      { name: "Basic", usage: "250,000 req / Month", price: "$450 per month" },
      { name: "Standard", usage: "500,000 req / Month", price: "$825 per month" },
      { name: "Advanced", usage: "1,000,000 req / Month", price: "$1500 per month" },
      { name: "Enterprise", usage: "Unlimited", price: "Contact us for pricing" },
    ],
  },
  {
    title: "Incapsula Pricing",
    plans: [
      {
        name: "Trial",
        usage: "500 req / Week",
        price: "Free one-time, one-week trial for all clients",
      },
      { name: "Starter", usage: "100,000 req / Month", price: "$150 per month" },
      { name: "Basic", usage: "250,000 req / Month", price: "$250 per month" },
      { name: "Standard", usage: "500,000 req / Month", price: "$425 per month" },
      { name: "Advanced", usage: "1,000,000 req / Month", price: "$800 per month" },
      { name: "Enterprise", usage: "Unlimited", price: "Contact us for pricing" },
    ],
  },
  {
    title: "PerimeterX Web Pricing",
    plans: [
      {
        name: "Trial",
        usage: "500 req / Week",
        price: "Free one-time, one-week trial for all clients",
      },
      { name: "Starter", usage: "50,000 req / Month", price: "$100 per month" },
      { name: "Basic", usage: "100,000 req / Month", price: "$150 per month" },
      { name: "Business", usage: "300,000 req / Month", price: "$400 per month" },
      { name: "Enterprise", usage: "Unlimited", price: "Contact us for pricing" },
    ],
  },
  {
    title: "Other Services",
    plans: [
      { name: "Custom Websites", usage: "Varies", price: "Vary on specific details" },
      { name: "Custom Software", usage: "Varies", price: "Vary on specific details" },
      { name: "Consulting", usage: "Hourly", price: "$100 - $200 per hour" },
    ],
  },
];

function PricingPage() {
  const routes = RoutesConfig();
  return (
    <>
      <DefaultNavbar routes={routes} dark />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MKBox
        display="flex" // Enable flexbox
        flexDirection="column" // Ensure the items are stacked vertically (optional based on your layout)
        px={1}
        width="100vw"
        minHeight="100vh"
        mx="auto"
        position="relative"
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
        margin="auto"
        zIndex={2}
        pt={10} // Optional padding to account for the navbar
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center" // Aligns content below the menu
          width="90%" // Set to 90% of viewport width
        >
          <Grid item xs={12} width="80%" margin="auto">
            <Card>
              <div
                style={{
                  padding: "20px",
                  maxWidth: "90%",
                  margin: "auto",
                  backgroundColor: "white",
                }}
              >
                {pricingData.map((product, index) => (
                  <Box key={index} sx={{ mb: 5 }}>
                    <Typography variant="h4" align="center" sx={{ mb: 3, color: "#1976d2" }}>
                      {product.title}
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        height: "4px",
                        background: "linear-gradient(to right, #e3f2fd, #bbdefb)",
                        mb: 2,
                      }}
                    ></Box>
                    <Box sx={{ overflowX: "auto" }}>
                      <Grid container spacing={3} sx={{ display: "flex", flexWrap: "nowrap" }}>
                        {product.plans.map((plan, idx) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={idx}
                            sx={{ minWidth: "250px" }}
                          >
                            <Paper
                              elevation={3}
                              sx={{
                                p: 2,
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="subtitle1"
                                  align="center"
                                  sx={{ fontWeight: "bold" }}
                                >
                                  {plan.name}
                                </Typography>
                                <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                                  {plan.usage}
                                </Typography>
                                <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                                  {plan.price}
                                </Typography>
                              </Box>
                              <Box sx={{ mt: "auto" }}>
                                <Button variant="contained" color="info" fullWidth>
                                  Choose Plan
                                </Button>
                              </Box>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                ))}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PricingPage;
